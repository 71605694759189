import React, {useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    TextInput,
    ScrollView,
    Pressable
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { WeekConfiguration, createEmptyWeek, dayNames, generatePeakObj, setPeak } from "../components/common/utils/peak-off-peak-field";
import DropdownComponentEnd from "../components/DropdownComponentEnd";
import GooglePlacesInput from "../components/GooglePlacesInput";
import { getCoordinatesFromGeodata, getZipCodeFromGeodata, GetGoogleGeocode as GetGoogleGeodata, getCityFromGeodata, getStateFromGeodata, getCountryFromGeodata, getStreetNameFromGeodata, getStreetNumberFromGeodata } from '../components/common/utils/google-api-utils';
import { Place } from "react-native-google-places-autocomplete";
import { disableCharger, enableCharger, getAllChargers, getMyChargers, getResidentials, updateCharger } from "../store/actions/charging-actions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../services/api-requests";
import {useDispatch, useSelector} from 'react-redux';
import { notificationService } from "../services/notification-service";
import { ChargerAttributes, createEmptyCharger } from "../models/charger-model";
import { RootState } from "../store";
import DropdownComponentStart from "../components/DropdownComponentStart";
import RegisterChargerCheckbox from "./RegisterChargerCheckbox";

const { width, height } = Dimensions.get("window");

export default function AddModifyCharger({navigation, route}) {

    const [isUpdating, setIsUpdating] = useState(false);
    const [geodata, setGeodata] = React.useState(null);

    const [peakSelection, setPeakSelection] = React.useState(generatePeakObj('000000000000000000000000000000000000000000'));
    const [uuid, setUuid] = React.useState();
    const [editChargerStatus, setEditChargerStatus] = useState(false);
    const [checkboxSelected, setCheckboxSelected] = useState({
        updatedDay: [''],
        startEndTime: [{}]
    });
    const[generatePeakObjCheckbox, setGeneratePeakObjCheckbox] = useState({});
    //const [chargerDetails, setChargerDetails] = useState(null);

    const selectedCharger: ChargerAttributes = useSelector((state: RootState) => state.chargers)

    // const selectedChargerResp = selectedCharger?.filter(charger=>{
    //         if(charger.uuid === chargerDetails.uuid){
    //             return charger;
    //         }
    // })
    
    const [form, setForm] = React.useState<ChargerAttributes>(
        createEmptyCharger()
    );
    const[enableDisCharger, setEnableDisCharger] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const setSelectedDays = (objCheckbox: any) => {
        setGeneratePeakObjCheckbox(objCheckbox);
    }

    useEffect( () => {

    const getChargingDetails = async() => {
        //check if we have been passed in a route
        if(route.params){
            if(!route.params.newCharger){
            //const editChargerStatus = route.params?.editCharger;
            setEditChargerStatus(true);
            const chargerDetails = route.params?.chargerDetails;

            const{name, unit_serial, full_address, description, price_hourly_peak, price_hourly_off_peak, peak_selection, isOccupied, uuid} = chargerDetails;
       
            setForm({
                ...form,
                name: name,
                unit_serial: unit_serial,
                full_address: full_address,
                description: description,
                price_hourly_peak: price_hourly_peak,
                price_hourly_off_peak: price_hourly_off_peak,
                uuid: uuid
            })

            setPeakSelection(peak_selection);

            const getGeneratedPeakObj = await generatePeakObj(peak_selection)
            
            let dateUpdated = [];
            let updatedDateTime = [];

            for (const key in getGeneratedPeakObj) {
                if (getGeneratedPeakObj?.hasOwnProperty(key)) {
                  if(getGeneratedPeakObj[key].start !== 0 && getGeneratedPeakObj[key].end !== 0){
                    dateUpdated.push(key);
                    updatedDateTime.push(getGeneratedPeakObj[key])
                  }
                }
              }

              setCheckboxSelected({
                ...checkboxSelected,
                updatedDay: dateUpdated,
                startEndTime: updatedDateTime
            })
              setGeneratePeakObjCheckbox(getGeneratedPeakObj)
            }
        else{
                setForm({
                    ...form,
                    unit_serial: route.params.unit_serial,
                })
            }
        }
    }
    getChargingDetails();
    }, [])

    useEffect(()=> {
        const fullAddress = form?.full_address;
        if(fullAddress){
            const googleAddress = {
                description : fullAddress
            }
            handleAddressSelect(googleAddress, '')
        }
        
    },[form?.full_address])

    const handleUpdateCharger = async () => {
        setIsUpdating(true)
        const { description, lat, lng, uuid, name, unit_serial, full_address, street_number, street_name, zip_code, city, state, country, 
            price_hourly_peak, price_hourly_off_peak
        } = form;
    
        const charging_capacity = 40;
        
        const myLocation = {
            latitude: lat,
            longitude: lng
        }
        
        //const residentials = await getResidentials(myLocation);
        const residentials = null;
        //const updatedResidentials = residentials?.outputs.residential;
        const updatedResidentials = null;

        const respPeakSelection = setPeak(peakSelection)

        let cost_est_kwh_peak_update;
        let cost_est_kwh_off_peak_update;

        if( updatedResidentials !== null && updatedResidentials !== 'no data'){
            cost_est_kwh_peak_update  = updatedResidentials  * charging_capacity * 2;
            cost_est_kwh_off_peak_update = updatedResidentials * charging_capacity;
        } else {
            cost_est_kwh_peak_update  = 3 * charging_capacity;
            cost_est_kwh_off_peak_update = 3 * charging_capacity;
        }

        const payload = {
            charger_model: "Emporia X1",
            charger_type: "Level 2",
            charging_capacity: 40,
            cost_est_kwh: 2.88,
            description: description,
            isOccupied: false,
            manufacturer_name: "Emporia",
            name,
            unit_serial: unit_serial,
            lat,
            lng,
            full_address,
            street_number,
            street_name,
            zip_code,
            city,
            state,
            country,
            price_hourly_peak,
            price_hourly_off_peak,
            cost_est_kwh_peak: cost_est_kwh_peak_update,
            cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
            peak_selection: respPeakSelection
        }
        
        setTimeout(()=> {
            navigation.navigate('MyChargers',{
                state: route?.params?.state === false ? true : false
            });
        },2000)

        dispatch(updateCharger(payload, uuid))
    }

    const onEnableDisableCharger = async () => {
        setEnableDisCharger(!enableDisCharger);
        const enableDisableuuid = route.params?.chargerDetails?.uuid
        if(enableDisCharger === true){
            //dispatch(disableCharger(enableDisableuuid))
            await disableCharger(enableDisableuuid);
        }
        else{
            //dispatch(enableCharger(enableDisableuuid))
            await enableCharger(enableDisableuuid);
        }

    }

    const handleSubmit = async (searchString:any) => {
        const { description, 
                lat, 
                lng, 
                uuid, 
                name, 
                unit_serial, 
                full_address, 
                street_number, 
                street_name, 
                zip_code, 
                city, 
                state, 
                country, 
                price_hourly_peak, 
                price_hourly_off_peak
        } = form;
     
        //TODO: grab this from the NREL estimator
        //form.cost_est_kwh_off_peak=2.88;
        //form.cost_est_kwh_peak=2.88*2;

        const charging_capacity = 40;

        const myLocation = {
            latitude: lat,
            longitude: lng
        }
        
        const respPeakSelection = await setPeak(peakSelection)

        //const residentials = await getResidentials(myLocation);
        const residentials = null;
        //const updatedResidentials = residentials?.outputs.residential;
        const updatedResidentials = null;

        let cost_est_kwh_peak_update;
        let cost_est_kwh_off_peak_update;

        if( updatedResidentials !== null && updatedResidentials !== 'no data'){
            cost_est_kwh_peak_update  = updatedResidentials  * charging_capacity * 2;
            cost_est_kwh_off_peak_update = updatedResidentials * charging_capacity;
        } else {
            cost_est_kwh_peak_update  = 3 * charging_capacity;
            cost_est_kwh_off_peak_update = 3 * charging_capacity;
        }


        const payload = {
            charger_model: "Emporia X1",
            charger_type: "Level 2",
            charging_capacity: 40,
            cost_est_kwh: 2.88,
            description: description,
            isOccupied: false,
            manufacturer_name: "Emporia",
            name,
            unit_serial: unit_serial,
            lat,
            lng,
            full_address,
            street_number,
            street_name,
            zip_code,
            city,
            state,
            country,
            price_hourly_peak,
            price_hourly_off_peak,
            cost_est_kwh_peak: cost_est_kwh_peak_update,
            cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
            peak_selection: respPeakSelection
        }

        try{
            const apiQuery = PrepareApiProcedure('charger/registerCharger', 'POST', 'charger', payload);
            const registerResp = await fetchDataFromAPI(apiQuery);
            
            if (!registerResp) {
                notificationService.sendNotification("error", `Could not register this charger`);
            } else {
                notificationService.sendNotification("success", 'Successfully registered your charger!');
                setUuid(registerResp?.charger.uuid);
                //dispatch(getAllChargers());
                dispatch(getMyChargers());
                navigation.navigate('MyChargers');
            }
        } catch (e) {
            console.error("Error while registering charger", e)
        }
    }

    const handleAddressSelect = async (data: Place, details: any) => {
        
        const newGeodata = await GetGoogleGeodata(data.description)

        const getCity = await getCityFromGeodata(newGeodata)
        const getState = await getStateFromGeodata(newGeodata)
        const getCountry = await getCountryFromGeodata(newGeodata)
        const getCoordinates = await getCoordinatesFromGeodata(newGeodata)
        const getStreetName = await getStreetNameFromGeodata(newGeodata)
        const getStreetNumber = await getStreetNumberFromGeodata(newGeodata)
        const getZipCode = await getZipCodeFromGeodata(newGeodata)
        //TODO: handle if coordinates are not retrieved
        setForm({
            ...form,
            full_address: data.description,
            city: getCity,
            state: getState,
            country: getCountry,
            lat: getCoordinates.latitude,
            lng: getCoordinates.longitude,
            street_name: getStreetName,
            street_number: getStreetNumber,
            zip_code: getZipCode
        })
        
        setGeodata(newGeodata)
    }

    React.useEffect(() => {
        setTimeout(()=>{
            dispatch(getAllChargers());
        },2000)
    }, [enableDisCharger]);
    
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className="relative">
                        <Image
                        source={require("../../assets/RegisterBgImage.png")}
                        style={{ aspectRatio: 1}}
                        alt="Background Image"
                        />
                        <Pressable className="absolute bottom-0 right-0 p-2 mb-2 mr-2 rounded-lg" style={{backgroundColor: '#0B1E1D'}}>
                            <Text className="text-sm font-semibold text-white">Change Photo</Text>
                        </Pressable>
                    </View>
                    
                    <View className="p-6">
                        {
                            editChargerStatus && (
                                <View>
                                    {selectedCharger.isOccupied === true || enableDisCharger === true? (
                                        <Pressable style={{backgroundColor: '#FBD644'}} className="p-3 mt-4 rounded-full" onPress={onEnableDisableCharger}>
                                            <Text className="text-lg font-bold text-center text-black text-white">Disable Charger</Text>
                                        </Pressable>
                                    ):(
                                        <Pressable style={{backgroundColor: '#008000bf'}} className="p-3 mt-4 rounded-full" onPress={onEnableDisableCharger}>
                                            <Text className="text-lg font-bold text-center text-black text-white">Enable Charger</Text>
                                        </Pressable>
                                    )}

                                    <Text className="mt-2 text-xs font-medium text-white">Temporarily disable or enable your charger -  Guests will not be able to find it or charge with it through the app. This is useful if you want to manually use the charger yourself without the app.</Text>
                                    <View style={styles.line} />
                                </View>
                            )
                        }
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Name</Text>
                        <TextInput 
                            placeholder="Enter charger name"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.name}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    name: e.nativeEvent.text
                                })
                              }}
                        />
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Serial Number</Text>
                        <TextInput 
                            placeholder="Enter serial number"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.unit_serial}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    unit_serial: e.nativeEvent.text
                                })
                              }}
                        />
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Street Address</Text>
                        <GooglePlacesInput onPlaceSelect={handleAddressSelect} value={form?.full_address}/>

                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Description</Text>
                        <TextInput 
                            placeholder="Enter charger description"
                            placeholderTextColor={'#8D8E90'}
                            multiline={true}
                            numberOfLines={5}
                            style={[styles.BgColor1, { borderRadius: 16 }]}
                            className="w-full p-3 text-white"
                            value={form?.description}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    description: e.nativeEvent.text
                                })
                              }}
                        />

                        <View style={styles.line} />

                        <View>
                            <Text className="text-base font-semibold text-white">Hourly Rate</Text>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput 
                                        placeholder="25.00"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={form?.price_hourly_peak}
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                price_hourly_peak: e.nativeEvent.text
                                            })
                                          }}
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per hour before fees</Text>
                            </View>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Off-Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput 
                                        placeholder="35.00"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={form?.price_hourly_off_peak}
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                price_hourly_off_peak: e.nativeEvent.text
                                            })
                                          }}
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per hour before fees</Text>
                            </View>
                        </View>

                        <View style={styles.line} />

                        <View>
                            <Text className="mb-4 text-base font-semibold text-white">Peak Hours</Text>
                            <RegisterChargerCheckbox 
                                setPeakSelection={setPeakSelection} 
                                checkboxSelected={checkboxSelected}
                                generatePeakObjCheckbox={generatePeakObjCheckbox} 
                                setSelectedDays={setSelectedDays}
                                editChargerStatus={editChargerStatus}
                            />
                        </View>
                        {editChargerStatus ===  true ? (
                            <Pressable onPress={handleUpdateCharger} style={{backgroundColor: '#39BEB7'}} className="p-3 mt-4 rounded-full">
                            <Text className="text-lg font-medium text-center text-white">Update Charger</Text>
                            </Pressable>
                        ) : (
                            <Pressable onPress={handleSubmit} style={{backgroundColor: '#39BEB7'}} className="p-3 mt-4 rounded-full">
                            <Text className="text-lg font-medium text-center text-white">Create Charger</Text>
                            </Pressable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
    },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        borderRadius: 5,
    },
})