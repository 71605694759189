import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { useDispatch } from 'react-redux';
import { setSession } from '../store/actions/user-session';
import { loginUser, oauthLogin, registerUser, validateUserToken } from '../store/actions/login-register-logout-action';
import { userActions } from '../store/slices/user-slice';
import store from "../store"; 

export const getUserSessionObj = async (): Promise<UserSessionAttributes | undefined> => {

    const token =  await AsyncStorage.getItem("token");
    const IpAddress = await AsyncStorage.getItem("IpAddress");
    const email = await AsyncStorage.getItem("email");
    const UserId = await AsyncStorage.getItem("userId");
    let createSession;
    let sessionObj;
    if(token !== null && IpAddress !== null && email !== null && UserId !== null){
        sessionObj = createUserSession({
            access_token: token, 
            IpAddress: IpAddress, 
            email: email, 
            id: UserId,
        })
        //store.dispatch(setSession(createSession))
    } else {
        //not logged in, set the guest login
        const ip = await getIpAddressFunc();
        createSession = createUserSession({
            email: "guest",
            password: "meanderev-rocks!",
            IpAddress: ip,
            loginType: true,
            access_token: "",
        });

        //get the token
        //store.dispatch(userActions.loginUser({userLogin: createSession}));
        //store.dispatch(setSession(sessionObj))
        sessionObj = await loginUser(createSession);
    }
    
    return sessionObj;
}