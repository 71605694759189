//The user session is the user's logged in data.
// the session is empty if the user is not logged in
// this data also exists in localStorage

import { PrepareApiProcedure } from "../../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../../services/api-requests";
import { notificationService } from "../../services/notification-service";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { throwIfEmpty } from "rxjs";
import { UserInfo } from "../../models/user-info";
import { userActions } from "../slices/user-slice";
import { UserSessionAttributes, createUserSession } from "../../models/user-session-model";

export const setSession = (sessionObj: UserSessionAttributes) => {
    return async (dispatch: (arg0: {payload: any; type: string; }) => void) => {
        try {
            dispatch(userActions.setUserSession(
                {
                    session: sessionObj
                }
            ));
        } catch (error) {
            console.error(error);
        }
    }   
}

export const clearSession = (userSession: UserSessionAttributes) => {
    return async (dispatch: (arg0: {payload: any; type: string; }) => void) => {
        if(userSession !== undefined){
            try {
                //log out of server
                const apiQuery = PrepareApiProcedure("logout", "POST", 'logout', {
                    email: userSession.email,
                    IpAddress: userSession.IpAddress,
                });
                const loggedOut = await fetchDataFromAPI(apiQuery);
                console.log(loggedOut);
                //clear out the async storage
                await AsyncStorage.clear();
                //clear the user session
                dispatch(userActions.setUserSession(
                    undefined
                ))
            } catch(error) {
                console.error(error);
            }
        }
    }
}