import AsyncStorage from '@react-native-async-storage/async-storage';

export const fetchDataFromAPI = async (apiQuery: any) => {
    try{
        const jwtToken = await AsyncStorage.getItem("token");
        const IpAddress = await AsyncStorage.getItem("IpAddress");
        const email = await AsyncStorage.getItem("email");

        const response = await fetch(process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
            {
                method: apiQuery.request.action,
                headers:{
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json',
                    'x-username': `${email}`,
                    'x-ip-address':`${IpAddress}`,
                    'authorization': 'Bearer ' + jwtToken
                },
                //credentials: 'include',
                body: (apiQuery.request.data === '') ? null : JSON.stringify(apiQuery.request.data)
            });
        
        if(!response.ok){
            const errorData = await response.json();

            /* Check the error status code / message
            * and verify if we need to suppress or not
            */
            if ((response.status === 3003)){
                /* do something */
                return errorData;
            } else {
                //send notification to browser via notification service
                return {...errorData, errStatus:response.status};
            }
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.error('Error in fetchDataFromAPI:', err);
        throw err;
    }
}