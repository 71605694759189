//Charger Scan Handler manages incoming QR scans
// The Serial Number will be turned into the charger UUID
// The charger will be selected
// And the ChargingStation screen will be loaded

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { updateSelectedCharger } from '../redux/actions';
import { chargerQrScan, getMyDistanceFromCharger, getMyDrivingTime } from '../../store/actions/charging-actions';
import { RootState } from '../../store';
import { ChargerAttributes } from '../../models/charger-model';
import * as Location from 'expo-location';
import {
    Platform
  } from "react-native";
import { notificationService } from '../../services/notification-service';
import { RootStackParamList } from '../MeanderStack';
import { getUserSessionObj } from '../../services/user-session-service';
import { UserSessionAttributes } from '../../models/user-session-model';
import { setSession } from '../../store/actions/user-session';

const ChargerScanHandler = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    let [myLocation, setMyLocation] = useState<Location.LocationObjectCoords | null>(null);

    const route = useRoute<RouteProp<RootStackParamList, 'ChargerScan'>>();
    const { chargerSerial } = route.params;

    const selectedCharger: ChargerAttributes = useSelector((state: RootState) => state.chargers.selectedCharger);
    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);


    useEffect(() => {
        const fetchChargerAndNavigate = async () => {
            if(session){
                //no need to try to fetch if we are not logged in and there is no user session
                try {
                    dispatch(chargerQrScan(chargerSerial));
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchChargerAndNavigate();
    }, [chargerSerial, dispatch, navigation, session]);

    useEffect(() => {

        //log in the user
        const getSessionObj = async () => {
            const sessionObj = await getUserSessionObj();
            dispatch(setSession(sessionObj));
        }

        const requestLocation = async () => {
            try {
                const { status } = await Location.requestForegroundPermissionsAsync();
    
                if (status === 'granted') {
                    const location = await Location.getCurrentPositionAsync({});
                    setMyLocation(location.coords);
                } else {
                    Platform.OS === 'web' ? '' :
                    notificationService.sendNotification('error', 'Location permission denied');
                }
            } catch (error) {
                notificationService.sendNotification('error', `Error requesting location permission: ${error}`);
            }
        };

        getSessionObj();
        requestLocation();
    }, []);

    useEffect(() => {
        
            const loadMyInfo = async () => {
                if(selectedCharger.uuid !== '' && myLocation && session){
                    const myDistance: string = await getMyDistanceFromCharger(myLocation, selectedCharger.uuid);
                    const myDrivingTime: string = await getMyDrivingTime(myLocation, selectedCharger.uuid);
                    navigation.navigate('ChargerStation', {
                        myDistanceFromCharger: myDistance[0], 
                        myTimeFromCharger: myDrivingTime[0]
                    });  // Navigate to the ChargerStation screen
                } else {
                    notificationService.sendNotification('error', 'Error retrieving charger')
                }
            }
        
        loadMyInfo();
        console.log("Trying to navigate to charger:", selectedCharger);
    }, [selectedCharger, myLocation, session])

    return null; // No UI is rendered by this component
};

export default ChargerScanHandler;