import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { createUser } from '../models/login-register-model';
import { registerUser } from '../store/actions/login-register-logout-action';
import { notificationService } from '../services/notification-service';
import { IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const { width, height } = Dimensions.get('window');

const CreateAccount = ({navigation}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isPasswordVisibleConfirm, setPasswordVisibleConfirm] = useState<boolean>(false);
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
    });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        
        switch (field) {
            case 'firstName':
              setFirstName(value);
              newErrors.name = value ? '' : 'Please enter your name.';
              break;
            case 'lastName':
              setLastName(value);
              newErrors.name = value ? '' : 'Please enter your name.';
              break;
            case 'email':
                setEmail(value);
                newErrors.email = !value ? 'Please enter your email address.' : validateEmail(value) ? '' : 'Please enter a valid email address.';
                newErrors.confirmEmail = value === confirmEmail ? '' : 'Email addresses do not match.';
                break;
            case 'confirmEmail':
                setConfirmEmail(value);
                newErrors.confirmEmail = value === email ? '' : 'Email addresses do not match.';
                break;
            case 'password':
                setPassword(value);
                newErrors.password = value ? '' : 'Please enter a password.';
                newErrors.confirmPassword = value === confirmPassword ? '' : 'Passwords do not match.';
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                newErrors.confirmPassword = value === password ? '' : 'Passwords do not match.';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const signUp = async () => {
        const newErrors = { ...errors };

        if (!firstName || !lastName) newErrors.name = "Please enter your name.";
        if (!email) newErrors.email = "Please enter your email address.";
        if (!confirmEmail) newErrors.confirmEmail = "Please confirm your email address.";
        if (email !== confirmEmail) newErrors.confirmEmail = "Email addresses do not match.";
        if (!password) newErrors.password = "Please enter a password.";
        if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match.";
        

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== "")) {
            return;
        }

        const registerObj = createUser({
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName
        });

        const resp = await registerUser(registerObj);

        if (resp?.length > 0) {
            notificationService.sendNotification("success", "Success! You may now log in.");
            setTimeout(() => {
                navigation.navigate('WelcomeScreen');
            }, 2000);
        } else {
            notificationService.sendNotification("error", "There was an issue while registering, please try again");
        }
    };

    const handleSubmit = () => {
        signUp();
    };

    return (
        <SafeAreaView>
            <View style={[styles.containerBgColor, { height: height}]}>
                <ScrollView>
                    <View className='p-4'>
                        <Text className='font-bold text-2xl text-white text-center'>Create an account</Text>
                        <Text style={styles.subHeaderText} className='font-normal text-base text-center mb-5'>
                            You will be able to log in with your email in the future, using the password you set below.
                        </Text>

                        <View>
                            <Text className='font-semibold text-base text-white pt-3 pb-2'>First Name</Text>
                            <TextInput
                                placeholder="Enter first name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="p-4 text-white w-full"
                                value={firstName}
                                onChangeText={(value: string) => handleInputChange('firstName', value)}
                            />
                            <Text className='font-semibold text-base text-white pt-3 pb-2'>Last Name</Text>
                            <TextInput
                                placeholder="Enter last name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="p-4 text-white w-full"
                                value={lastName}
                                onChangeText={(value: string) => handleInputChange('lastName', value)}
                            />
                            {errors.name ? <Text style={styles.errorText}>{errors.name}</Text> : null}

                            <Text className='font-semibold text-base text-white pt-3 pb-2'>Email</Text>
                            <TextInput
                                placeholder="Enter your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="p-4 text-white w-full"
                                value={email}
                                onChangeText={(value) => handleInputChange('email', value)}
                            />
                            {errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                            <Text className='font-semibold text-base text-white pt-3 pb-2'>Confirm Email</Text>
                            <TextInput
                                placeholder="Confirm your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="p-4 text-white w-full"
                                value={confirmEmail}
                                onChangeText={(value) => handleInputChange('confirmEmail', value)}
                            />
                            {errors.confirmEmail ? <Text style={styles.errorText}>{errors.confirmEmail}</Text> : null}

                            <View>
                                <Text className='font-semibold text-base text-white pt-3 pb-2'>Create a Password</Text>
                                <TextInput
                                    placeholder="Enter your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="p-4 text-white w-full"
                                    secureTextEntry={!isPasswordVisible}
                                    value={password}
                                    onChangeText={(value) => handleInputChange('password', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisible ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisible(!isPasswordVisible)}
                                    style={styles.createPasswordIcon}
                                />
                            </View>

                            {errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}

                            <Text className='font-semibold text-base text-white pt-3 pb-2'>Confirm Password</Text>
                            <View>
                                <TextInput
                                    placeholder="Confirm your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="p-4 text-white w-full"
                                    secureTextEntry={!isPasswordVisibleConfirm}
                                    value={confirmPassword}
                                    onChangeText={(value) => handleInputChange('confirmPassword', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisibleConfirm ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisibleConfirm(!isPasswordVisibleConfirm)}
                                    style={styles.createPasswordIconConfirm}
                                />
                            </View>
                            {errors.confirmPassword ? <Text style={styles.errorText}>{errors.confirmPassword}</Text> : null}

                            <Pressable onPress={handleSubmit} style={styles.button} className='rounded-full mt-5 text-center p-4'>
                                <Text className='font-medium text-lg text-white text-center'>Create my account</Text>
                            </Pressable>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    subHeaderText: {
        color: '#8D8E90',
    },
    BgColor: {
        backgroundColor: '#292D33',
    },
    errorText: {
        color: '#f38787',
        fontSize: 14,
        marginTop: 4,
    },
    button: {
        backgroundColor: '#39BEB7',
    },
    createPasswordIcon: {
        position: 'absolute',
        top: 45,
        alignSelf: 'flex-end' 
    },
    createPasswordIconConfirm:{
        position: 'absolute',
        alignSelf: 'flex-end'
    }
});

export default CreateAccount;
