import React, { useState, useEffect, createContext } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, Linking } from "react-native";
import LoginScreen from "./src/pages/login";
import {
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
  NavigationContainerRef,
  Route,
  useNavigationState,
} from "@react-navigation/native";
import MeanderStack from "./src/components/MeanderStack";
import Footer from "./src/components/Footer";
import FooterWrapper from "./src/components/FooterWrapper";
import { Provider as PaperProvider } from "react-native-paper";
import { Provider as StoreProvider, useDispatch } from "react-redux";
import store from "./src/store";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { AutocompleteDropdownContextProvider } from "react-native-autocomplete-dropdown";
import Toast from "react-native-toast-message";
import { notificationService } from "./src/services/notification-service";

import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import Icon from "react-native-vector-icons/MaterialIcons";

import { NativeWindStyleSheet } from "nativewind"; // Import NativeWindStyleSheet
import NoFooter from "./src/components/NoFooter";

NativeWindStyleSheet.setOutput({
  default: "native",
});

const Stack = createNativeStackNavigator();

// const customFonts = {
//   'MaterialIcons': require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
//   'Material Design Icons': require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf')
// }

type RootStackParamList = {
  //OAuthRedirect: { email?: string};
  //OAuthRedirect: undefined;
  ChargerScan: {chargerSerial: string };
}

// Define routes and corresponding paths
const linking: LinkingOptions<RootStackParamList> = {
  //prefixes: ['meanderapp://'], // deep link prefixes
  prefixes: ['https://app.meanderev.co'],
  //prefixes: ['http://localhost'],
  config: {
    screens: {
      //OAuthRedirect: 'oauthSuccess',
      ChargerScan: 'chargerscan/:chargerSerial',
    },
  },
};

interface NavigationContextProps {
  currentRouteName: string | undefined;
  setCurrentRouteName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

// suppress console logging in production
if (process.env.REACT_NATIVE_ENV === 'prod') {
  console.log = () => {};
}

const App = () => {
  //const [fontsLoaded, setFontsLoaded] = useState(false);

  //const dispatch = useDispatch();

  const navigationRef = useNavigationContainerRef<NavigationContainerRef<RootStackParamList>>();
  const [currentRouteName, setCurrentRouteName] = useState<string | undefined>();

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     const routeName = navigationRef.getCurrentRoute()?.name;
  //     setCurrentRouteName(routeName);
  //   }, 0);

  //   return () => clearTimeout(timeoutId);
  // }, [navigationRef]);

  useEffect(() => {
    const routeName = navigationRef.getCurrentRoute()?.name;
    setCurrentRouteName(routeName);
    //console.log("Initial Route Name:" , routeName);
  }, [navigationRef]);

  const onReady = () => {
    const routeName = navigationRef.getCurrentRoute()?.name;
    setCurrentRouteName(routeName);
    console.log('Navigation Ready. Current Route Name:', routeName);
  };

  const shouldRenderFooter = (currentRouteName !== 'WelcomeScreen') && (currentRouteName !== 'LoginWithAccount') && (currentRouteName !== 'CreateAccount');

  const [notification, setNotification] = React.useState<{
    type: string;
    message: string;
  }>({ type: "default", message: "" });

  notificationService.getNotification().subscribe((notification) => {
    if (notification) {
      setNotification(notification);
    }
  });

  React.useEffect(() => {
    //if (notification && notification.message && notification.message !== "") {
    if (notification.message !== "") {
      Toast.show({
        type: notification.type,
        text1: notification.message,
      });
    }
  }, [notification]);

  //Conditional rendering for footer
  // const currentRouteName = useNavigationState(state => state.routes[state.index]?.name);
  // const shouldRenderFooter = (currentRouteName !== 'WelcomeScreen') && (currentRouteName !== 'LoginWithAccount') && (currentRouteName !== 'CreateAccount');

  // React.useEffect(() => {
  //   const loadFonts= async () => {
  //     try {
  //       // Keep the splash screen visible while we fetch resources
  //       await SplashScreen.preventAutoHideAsync();
  //       // Load fonts
  //       await Font.loadAsync(customFonts);
  //       setFontsLoaded(true);
  //     } catch (e) {
  //       console.warn(e);
  //     } finally {
  //       // Hide the splash screen
  //       await SplashScreen.hideAsync();
  //     }
  //   }
  //   loadFonts();
  // }, []);

  // Icon.loadFont();

  // if (!fontsLoaded) {
  //   return null; // Return null or a placeholder view until fonts are loaded
  // }

  return (
    <StoreProvider store={store}>
      <GestureHandlerRootView style={styles.container} key={`GHRV`}>
        <AutocompleteDropdownContextProvider>
          <PaperProvider>
            <NavigationContext.Provider value={{ currentRouteName, setCurrentRouteName }}>
              <NavigationContainer 
                ref={navigationRef} 
                onReady={onReady} 
                onStateChange={() => {
                  const routeName = navigationRef.getCurrentRoute()?.name;
                  setCurrentRouteName(routeName);
                  console.log('State Changed. Current Route Name:', routeName);
                }}
                linking={linking}
              >
                <MeanderStack currentRouteName={currentRouteName}/>
                {!shouldRenderFooter ? (
                  <NoFooter />  
                )
                : (
                  <View>
                     {currentRouteName !== undefined && (
                      <Footer currentRouteName={currentRouteName}/>
                     )}
                  </View>
                )
              }
                <Toast />
              </NavigationContainer>
            </NavigationContext.Provider>
          </PaperProvider>
        </AutocompleteDropdownContextProvider>
      </GestureHandlerRootView>
    </StoreProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
  },
});

export default App;
