import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader'

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image } from 'react-native';

const { width, height } = Dimensions.get('window');

const QRScanner = ({navigation, myLocation}) => {
    const [qrResult, setQrResult] = useState('');

    // Function to handle QR code scan result
    const handleScan = (result:any, error:any, codeReader:any) => {
        if (result) {
            setQrResult(result.getText());
            console.log('QR code data:', result.getText());
            navigation.navigate('ChargerStation')
        } 
        // else {
        //     console.error('An error occurred while scanning:', error);
        // }
    };

    // Function to handle scan error
    // const handleError = (error:any) => {
    //     console.error('An error occurred while scanning:', error);
    // };
    

    return(
        <SafeAreaView>
            <View style={{height: height - 65}}>
                    <View>
                        <Text className='font-bold text-lg text-white text-center'>Scan QR Code</Text>
                        <Text className='font-normal text-lg text-center' style={{color: '#8D8E90'}}>Please point the camera at the QR Code</Text>
                        <Text className='font-normal text-lg text-center text-white'>{qrResult}</Text>
                        <QrReader
                            constraints={{advanced: [{ facingMode: 'environment' }]}} // Specify media track constraints
                            onResult={handleScan} // Callback function for QR code scan result
                            //error={handleError} // Callback function for scan error
                            scanDelay={300} // Delay between scans in milliseconds
                            videoId="qr-video" // ID of the video element
                            className="qr-scanner" // Optional class name for styling
                            containerStyle={{ width: '100%' }} // Style for the container
                            videoContainerStyle={{ width: '100%' }} // Style for the video container
                            videoStyle={{ width: '100%' }} // Style for the video
                        />
                    </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
   
})

export default QRScanner