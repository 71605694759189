import { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions, Image } from 'react-native';
import RBSheet from "react-native-raw-bottom-sheet";
import GoogleAuthButton from '../components/google-auth-button';
import SignInFieldsOnly from './SignInFieldsOnly';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { createUserSession } from '../models/user-session-model';
import { loginUser } from '../store/actions/login-register-logout-action';
import { useDispatch } from 'react-redux';
import { userActions } from '../store/slices/user-slice';

const { width } = Dimensions.get('window');

const SignInOptions = ({
    navigation, 
    refRBSheet,
    destination,
}) => {
    const [signInWithEmail, setSignInWithEmail] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(385)
    const dispatch = useDispatch();

    const handleSignInWithEmail =  () => {
       setHeight(height + (signInWithEmail ? -150 : 150));
        setSignInWithEmail(!signInWithEmail);
    }

    const handleSignUpPress = () => {
        refRBSheet.current.close();
        navigation.navigate('CreateAccount')
    }

    const handleSignInSubmit = async (email: string, password: string) => {
      const ipAddress = await getIpAddressFunc()
        
      const loginObj = createUserSession({
        email: email,
        password: password,
        IpAddress: ipAddress,
      });
  
      const resp = await loginUser(loginObj);
      if (resp) {
        refRBSheet.current.close()
        navigation.navigate(destination);
        dispatch(userActions.setUserObj({userObj: resp.user}));
      }
    }

    return (
        <SafeAreaView>
            <RBSheet
                    ref={refRBSheet}
                    height={height}
                    duration={250}
                    closeOnDragDown={true}
                    closeOnPressMask={true}
                    customStyles={{
                        container: {
                        borderRadius: 20,
                        backgroundColor: "#1A1D21",
                        width: width <= 430 ? width*0.98 : 420,
                        height: height,
                        position: "relative",
                        display: "flex",
                        alignSelf: "center",
                        paddingBottom: 70,
                        marginBottom:0,
                        },
                    }}
                >   
                    <View>
                        <View style={{ flex:1, alignItems: 'center' }}>
                            <Image 
                                source={require('../../assets/headerBarIcon.png')} 
                                style={{ width: 55, height: 5, marginTop: 12 }}
                            />
                        </View>
                        <View className='p-4'>
                            {/* <Pressable className='flex-row items-center bg-white rounded-full py-3 px-4 my-1'>
                                <AntDesign name="google" size={24} color="black" />
                                <Text className='text-black text-lg font-medium ml-4'>Continue with Google</Text>
                            </Pressable> */}
                            <GoogleAuthButton 
                                navigation={navigation}
                                RBRef = {refRBSheet}
                                destination={destination}
                            />
                            {/* <Pressable className='flex-row items-center bg-white rounded-full py-3 px-4 my-1'>
                                <FontAwesome5 name="facebook" size={24} color="black" />
                                <Text className='text-black text-lg font-medium ml-4'>Continue with Facebook</Text>
                            </Pressable>
                            <Pressable className='flex-row items-center bg-white rounded-full py-3 px-4 my-1'>
                                <FontAwesome name="apple" size={24} color="black" />
                                <Text className='text-black text-lg font-medium ml-4'>Continue with Apple</Text>
                            </Pressable> */}

                            <Text className='font-normal text-lg my-4 text-center' style={styles.textColor}>or</Text>

                            <Pressable style={styles.bgColor} className='p-4 rounded-full'
                                onPress={handleSignInWithEmail}
                            >
                                <Text style={styles.textColorWhite} className='font-medium text-lg text-white text-center'>
                                    Sign in with email
                                </Text>
                            </Pressable>
                            
                            {signInWithEmail && 
                                <View style={{ width: '100%', height: 260}}>
                                    <SignInFieldsOnly
                                        onSubmit={handleSignInSubmit}
                                    />
                                </View>
                            }

                            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 10 }} className='flex-row gap-3 justify-center mt-2'>
                                <Text style={styles.textColor} className='font-normal text-sm'>
                                    Don’t have an account?
                                </Text>
                                <Text style={styles.textColor1} className='font-bold text-sm'
                                    onPress={handleSignUpPress}
                                >
                                    Sign up
                                </Text>
                            </View>
                        </View>
                    </View>
                </RBSheet>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor1: {
        color: '#39BEB7',
    },
    textColorWhite: {
        color: 'white',
    },
    bgColor: {
        backgroundColor: '#39BEB7',
    }
});

export default SignInOptions;
