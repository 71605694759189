import { createSlice } from '@reduxjs/toolkit';
import { createEmptyUser, userInfo } from '../../models/user-info'
import { UserSessionAttributes } from '../../models/user-session-model';
import { createUserSession } from '../../models/user-session-model';
import { UserAttributes, createUser } from '../../models/login-register-model';

interface UserData {
    profile: userInfo;
    userObj: UserAttributes;
    session: UserSessionAttributes | undefined;
}

const initialState: UserData = {
    profile: createEmptyUser(),
    userObj: createUser(),
    session: undefined,
}

const userSlice = createSlice ({
    name: 'user',
    initialState,
    reducers: {
        loginUser(state, action) {
            //Receive a user's profile as a state when they log in
            state.profile = action.payload.profile
        },
        setUserObj(state, action) {
            state.userObj = action.payload.userObj
        },
        setUserSession(state, action) {
            //Receive a user's profile as a state when they log in
            state.session = action.payload.session
        }
    }
});

export const userActions = userSlice.actions;
export default userSlice;