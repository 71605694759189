import { getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerAttributes } from "../models/charger-model";
import { fetchDataFromAPI } from "./api-requests";
import { notificationService } from "./notification-service";

// FUNCTION: getMyChargingHistory
// PURPOSE: Retrieve the charging history for chargers that I have used
export const getMyChargerHistory = async () => {
    try {
        // Fetch charger histories
        const apiQuery = PrepareApiProcedure(
            'charger/getMyChargerHistories/',
            'GET',
            'chargerHistory',
            ''
        );
        const chargingHistoryData = await fetchDataFromAPI(apiQuery);
        return chargingHistoryData;

    } catch (error) {
        console.log(error.message);
        notificationService.sendNotification('error', `Error retrieving your charging history! ${error.message}`);
    }
}

export const getChargerHistoryByTime = async (charger: ChargerAttributes, startTime: Date, endTime: Date) => {
    const apiQuery = PrepareApiProcedure(
        `charger/chargerHistoryByTime/${charger.uuid}/${getDateInUtcSeconds(startTime)}/${getDateInUtcSeconds(endTime)}`,
        'GET',
        'chargerHistory',
        ''
    );
    const chargerHistory = await fetchDataFromAPI(apiQuery);
    return chargerHistory;
}

// * FUNCTION: getChargerHistorybyUser
// * PURPOSE: retrieve a user's charging history
export const getChargingHistoryByUser = async (startTime?: Date, endTime?: Date) => {
    const apiQuery = PrepareApiProcedure(
        `charger/chargerHistoryByUser/${startTime ? getDateInUtcSeconds(startTime) : 0}/${endTime ? getDateInUtcSeconds(endTime) : 0}`,
        'GET',
        'chargerHistory',
        ''
    );
    const myChargingHistory = await fetchDataFromAPI(apiQuery);
    return myChargingHistory;
}