import { createSlice } from '@reduxjs/toolkit';
import { ChargerInfo, emptyCharger } from '../../models/charger-info';
import { ChargerAttributes, createEmptyCharger } from '../../models/charger-model';
import { ChargerHistoryAttributes } from '../../models/charger-history-model';

interface ChargerData {
    chargerInputs: ChargerAttributes;
    chargers: ChargerAttributes[];
    chargerHistory: ChargerHistoryAttributes[];
    myChargers: ChargerAttributes[];
    selectedCharger: ChargerAttributes;
}

const initialState: ChargerData = {
    chargerInputs: createEmptyCharger(),
    chargers: [],
    chargerHistory: [],
    myChargers: [],
    selectedCharger: createEmptyCharger(),
}

const chargerSlice = createSlice ({
    name: 'chargers',
    initialState,
    reducers: {
        registerNewCharger(state, action) {
            console.log('registerNewCharger', action.payload)
            state.chargerInputs = action.payload.chargerInputs;
        },
        getAllChargers(state, action) {
            console.log('getAllChargers', action.payload);
            state.chargers = action.payload.chargers;
        },
        getChargerHistory(state, action) {
            console.log('getChargerHistory', action.payload);
            state.chargerHistory = action.payload.chargerHistory;
        },
        getMyChargers(state, action) {
            console.log('getMyChargers', action.payload);
            state.myChargers = action.payload.myChargers;
        },
        updateSelectedCharger(state, action) {
            console.log('updateSelectedCharger', action.payload);
            state.selectedCharger = action.payload.selectedCharger;
        }
    }
})

export const chargerActions = chargerSlice.actions;
export default chargerSlice;
