import { configureStore } from '@reduxjs/toolkit';
import chargerSlice from './slices/charger-slice';
import userSlice from './slices/user-slice';

const store = configureStore({
    reducer: {
        chargers: chargerSlice.reducer,
        user: userSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck:false,
        }),
}); 

export type RootState = ReturnType<typeof store.getState>
export default store;