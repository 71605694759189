import React, { useState } from 'react';

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { IconButton } from 'react-native-paper';

const { width, height } = Dimensions.get('window');
const AccountInfo = ({route}:any) => {
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isPasswordVisibleConfirm, setPasswordVisibleConfirm] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [accountInfo, setAccountInfo] = useState({
        fullName: "",
        email: ""
    })

    const isChangePassword = route?.params?.changePassword

    const handleSubmit = () => {
        console.log('HandleSubmit here...');
    }
    
    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, { height: height - 65 }]}>
                <ScrollView contentContainerStyle={styles.scrollViewContent}>

                    {isChangePassword ? (
                        <View>
                            <Text style={styles.mainHead}> Change Password </Text>
                            <View className='flex items-center mt-6'>
                                <Image 
                                    source={require('../../assets/newLogo.png')}
                                    style={{ width: 100, height: 100, alignItems: 'center' }}
                                />
                            </View>
                            <View>
                                <View>
                                    <Text className="font-semibold text-base text-white pt-3 pb-2">Create New Password</Text>
                                    <TextInput
                                        placeholder="Create New Password"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor1, { borderRadius: 16 }]}
                                        className="p-3 text-white w-full"

                                        onChange={(e) => {
                                        setPassword(e.nativeEvent.text);
                                        setErrors({ ...errors, password: "" });
                                        }}
                                        value={password}
                                        keyboardType="visible-password"
                                        secureTextEntry={!isPasswordVisible}
                                    />
                                    <IconButton
                                        icon={(props) => (
                                            <MaterialCommunityIcons
                                            name={isPasswordVisible ? "eye" : "eye-off"}
                                            {...props}
                                            style={{ color: "#01556F" }}
                                            />
                                        )}
                                        onPress={() => setPasswordVisible(!isPasswordVisible)}
                                        style={{ position: 'absolute',
                                            right: 0,
                                            top: 39}}
                                    />
                                </View>
                                
                                <View>
                                    <Text className="font-semibold text-base text-white pt-3 pb-2">Confirm New Password</Text>
                                    <TextInput 
                                        placeholder="Confirm New Password"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor1, { borderRadius: 16 }]}
                                        className="p-3 text-white w-full"

                                        secureTextEntry={!isPasswordVisibleConfirm}
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.nativeEvent.text);
                                            setErrors({ ...errors, confirmPassword: "" });
                                            }}
                                    />

                                    <IconButton
                                        icon={(props) => (
                                            <MaterialCommunityIcons
                                            name={isPasswordVisibleConfirm ? "eye" : "eye-off"}
                                            {...props}
                                            style={{ color: "#01556F" }}
                                            />
                                        )}
                                        onPress={() => setPasswordVisibleConfirm(!isPasswordVisibleConfirm)}
                                        style={{ position: 'absolute',
                                            right: 0,
                                            top: 39}}
                                    />
                                </View>
                            </View>
                        </View>
                    ): 
                    (
                        <View>
                            <Text style={styles.mainHead}> Account Information </Text>
                            <View className='flex items-center mt-6'>
                                <Image 
                                    source={require('../../assets/newLogo.png')}
                                    style={{ width: 100, height: 100, alignItems: 'center' }}
                                />
                            </View>
                            <View>
                                <Text className="font-semibold text-base text-white pt-3 pb-2">Full Name</Text>
                                <TextInput 
                                    placeholder="Enter your name"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor1, { borderRadius: 16 }]}
                                    className="p-3 text-white w-full"
                                    value={accountInfo.fullName}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, fullName: e.nativeEvent.text });
                                        }}
                                />
                                <Text className="font-semibold text-base text-white pt-3 pb-2">Email</Text>
                                <TextInput 
                                    placeholder="Enter your email"
                                    placeholderTextColor={'#8D8E90'}                    
                                    style={[styles.BgColor1, { borderRadius: 16 }]}
                                    className="p-3 text-white w-full"
                                    value={accountInfo.email}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, email: e.nativeEvent.text });
                                        }}
                                />
                            </View>
                        </View>
                    )}
                </ScrollView>
                {isChangePassword ? (
                    <View style={styles.buttonContainer}>
                        <Pressable onPress={handleSubmit} style={styles.button}>
                            <Text className="font-medium text-lg text-white text-center">Save Password</Text>
                        </Pressable>
                    </View>
                ):
                (
                    <View style={styles.buttonContainer}>
                        <Pressable onPress={handleSubmit} style={styles.button}>
                            <Text className="font-medium text-lg text-white text-center">Save Account Information</Text>
                        </Pressable>
                    </View>
                )}
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
    },
    containerBgColor: {
        flex: 1,
        backgroundColor: '#1A1D21',
        paddingBottom: 60,
    },
    scrollViewContent: {
        flexGrow: 1,
        padding: 16,
    },
    BgColor1: {
        backgroundColor: '#292D33',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 20,
        left: 16,
        right: 16,
    },
    button: {
        backgroundColor: '#39BEB7',
        padding: 16,
        borderRadius: 9999,
        alignItems: 'center',
    },
    mainHead:{
        color: 'white', 
        fontSize: 18,
        alignSelf: 'center',
        fontWeight: '400'
    }
})

export default AccountInfo;