import { getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerAttributes } from "../models/charger-model";
import { fetchDataFromAPI } from "./api-requests";

export const checkinCharger = async (chargerId: string) => {
    //check in the charger
    const apiQuery = PrepareApiProcedure(
        `charger/checkin/${chargerId}`,
        'POST', 
        'charger', 
        '');
    return await fetchDataFromAPI(apiQuery);
}

export const getChargerStatus = async (charger: ChargerAttributes) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getChargerCarData/${charger.uuid}`, 'GET', 'charger', '');
        const chargerStatus = await fetchDataFromAPI(apiQuery);
        return chargerStatus;
    } catch (error) {
        console.log(error);
    }
}

export const validateCharger = async (serialNumber: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/validate/${serialNumber}`, 'GET', 'charger', '');
        const validCharger = await fetchDataFromAPI(apiQuery);
        return validCharger;
    } catch (error) {
        console.log(error);
    }
}