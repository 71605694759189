import { UseSelector } from 'react-redux';
import { notificationService } from '../../../services/notification-service';

export const GetGoogleGeocode = async (address: string) => {
  try {
    //in 
    //geocode api: https://maps.googleapis.com/maps/api/geocode/json?address=
    //console.log('coordinate req:');
    //console.log(`https://maps.googleapis.com/maps/api/geocode/json?address=
    //${encodeURIComponent(address)}
    //&key=${process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY}`);
    const response = await fetch(
      //`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY}`
      `https://app.meanderev.co:8803/api/geocode?address=${encodeURIComponent(address)}`
    );

    if (!response.ok) {
      const errorData = await response.json();
      notificationService.sendNotification('error','Unable to retrieve coordinates for the given address');
      return
    }

    const data = await response.json();
    console.log('coordinate response:', data.results[0]);
    return data.results[0];
  } catch (e: any) {
    console.error('Error fetching coordinates:', e);
  }
}

export const getCityFromGeodata = (googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("locality")
    ).long_name
};

export const getStateFromGeodata = (googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("administrative_area_level_1")
    ).long_name
};

export const getCountryFromGeodata = (googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("country")
    ).long_name
};

export const getCoordinatesFromGeodata = (googleGeoData: any) => {
    const location = googleGeoData.geometry.location;
    return {
      latitude: location.lat,
      longitude: location.lng,
    };
};

export const getStreetNameFromGeodata =(googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("route")
    ).long_name
}

export const getStreetNumberFromGeodata =(googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("street_number")
    ).long_name
}

export const getZipCodeFromGeodata = (googleGeoData: any) => {
  return googleGeoData.address_components.find(
    (component) => component.types.includes("postal_code")
    ).long_name
}