import { PrepareApiProcedure } from "../../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../../services/api-requests";
import { LoginRegister, UserAttributes } from "../../models/login-register-model";
import { UserSessionAttributes } from "../../models/user-session-model";
import { notificationService } from "../../services/notification-service";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { throwIfEmpty } from "rxjs";
import { UserInfo } from "../../models/user-info";
import { userActions } from "../slices/user-slice"

export const validateUserToken = async () => {
  
  try {
    //const jwtToken = await AsyncStorage.getItem("token");
    const apiQuery = PrepareApiProcedure("user/validate", "GET", "validate", '');
    const response = await fetchDataFromAPI(apiQuery);
    //console.log("validated token resp: ", response);
    return response;
  } catch (error) {
    console.error('Error validating user: ', error);
  }
}

// Login User
export const loginUser = async (userLogin: UserSessionAttributes) => {
    try {

      const apiQuery = PrepareApiProcedure("login", "POST", "login", userLogin);
      const { loginType } = userLogin;
      //console.log(`sending query:`, apiQuery);

      //console.log(`to: ${process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api}`)
      //console.log("userLogin obj:", userLogin);

      const userInfoQuery = PrepareApiProcedure("user/info", "GET", "user", '');
      if(userLogin.access_token !== ""){
        //get the user object here
        const userObj = await fetchDataFromAPI(userInfoQuery);
        return userObj;
      }

        const response = await fetch(
          process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
          {
            method: apiQuery.request.action,
            headers: {
              "Accept": "*/*",
              "Content-Type": "application/json",
            },
            body:
              apiQuery.request.data === ""
                ? null
                : JSON.stringify(apiQuery.request.data),
          }
        );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.statusNum === "3003") {
          /* do something */
        } else {
          if(loginType === false){
            console.log(errorData);
            errorData.message === "Password is incorrect!" ?
              notificationService.sendNotification("error", `Password is incorrect`) :
              errorData.message === "Email id is incorrect!" ? notificationService.sendNotification("error", `Email id is incorrect!`)
                : notificationService.sendNotification("error", `Invalid Credentials`)
          }
        }
        return null;
      }
      //this is now the login data
      const data = await response.json();

      //console.log("received login information:", data);

      //clear the AsyncStorage

      const keys = await AsyncStorage.getAllKeys();
      await AsyncStorage.multiRemove(keys);

      if(data.message === "Maximum number of devices used!"){
        notificationService.sendNotification("error", `Maximum number of devices used!`);
      } else if (userLogin.email === 'guest') {
        notificationService.sendNotification("success", `Please consider signing up for an account :)`);
      } else if (data) {
        notificationService.sendNotification("success", `Login Successful`);
      } else {
        notificationService.sendNotification("error", `Invalid Credentials`);
      }

      // data.message === "Maximum number of devices used!" ?
      // notificationService.sendNotification("error", `Maximum number of devices used!`) : data
      //   ? notificationService.sendNotification("success", `Login Successfully`)
      //   : notificationService.sendNotification("error", `Invalid Credentials`);
  
          const IpAddress = data.IpAddress;
          const access_token = data.access_token;
          const email = userLogin.email;
          const role = data.role;
          const userName = `${data.first_name} ${data.last_name}`;
          const userId = JSON.stringify(data.id);
          
          await AsyncStorage.setItem("token", access_token);
          await AsyncStorage.setItem("IpAddress", IpAddress);
          await AsyncStorage.setItem("email", email);
          await AsyncStorage.setItem("role", role);
          await AsyncStorage.setItem("userId", data.id);

          //now grab the user data
          //const loginObj = await fetchDataFromAPI(userInfoQuery);

          //await AsyncStorage.setItem("userName", userName);
          
          //const userObj = await fetchDataFromAPI(userInfoQuery);
          //return userObj;

          return data;
        
    } catch (e) {
      console.error(e);
    }
  };

//register user
export const registerUser = async (userRegistration: UserAttributes) => {
  try {

    const apiQuery = PrepareApiProcedure("register", "POST", "register", userRegistration);
    console.log("sending request to", process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api)
    const response = await fetch(
      process.env.REACT_NATIVE_API_BASE_URL + apiQuery.api,
      {
        method: apiQuery.request.action,
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/json",
        },
        body:
          apiQuery.request.data === ""
            ? null
            : JSON.stringify(apiQuery.request.data),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.statusNum === "3003") {
        /* do something */
      } else {
        console.log(errorData);
      }
      return null;
    }

    const data = await response.json();
    //console.log(data);
    return [data];

  } catch (e) {
    console.error(e);
  }
}

export const oauthLogin = (profile: UserInfo) => {
  return async (dispatch: (arg0: {payload: any; type: string;}) => void) => {
    //should we be checking the server for registration here?
    // or grabbing the server registration?

    dispatch(userActions.loginUser(
      {
        profile: profile
      }
    ));
  }
}