import React, { useState, useRef, useEffect } from 'react';

import { SafeAreaView, View,Text, ScrollView,
     StyleSheet, Dimensions, TextInput, Image,
      Pressable } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import RBSheet from "react-native-raw-bottom-sheet";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from 'react-redux';
import { UserAttributes } from '../models/login-register-model';
import { RootState } from '../store';
import { clearSession } from '../store/actions/user-session';
import { UserSessionAttributes } from '../models/user-session-model';

const { width, height } = Dimensions.get('window');
const MyAccount = ({ navigation }) => {
    const user:UserAttributes = useSelector((state: RootState) => state.user.userObj);
    const userSession:UserSessionAttributes = useSelector((state: RootState) => state.user.session);
    const dispatch = useDispatch();
    console.log("Stored state user:", user);
    //const navigation = useNavigation();

    const handleLogout = async () => {
        dispatch(clearSession(userSession));

        await AsyncStorage.clear();

        const getToken = await AsyncStorage.getItem('token')
        
        navigation.navigate('WelcomeScreen', {
            token: getToken
        })
    }

    const refRBSheet = useRef();

    const OpenBottomSheet = () => {
        refRBSheet.current.open();
      }
    
      const CloseBottomSheet = () => {
        refRBSheet.current.close();
      }

    useEffect(() => {
        console.log(user);
        
        
    }, [])
    
    return(
        <SafeAreaView>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 60}]}>
                <ScrollView>
                    <View className='p-4'>
                        <View className='flex items-center mt-6'>
                            <Image 
                                source={require('../../assets/newLogo.png')}
                                style={{ width: 100, height: 100, alignItems: 'center' }}
                            />
                            <Text className='font-semibold text-lg text-white text-center'>
                                {`${user.first_name} ${user.last_name}`}
                            </Text>
                            <Text className='font-normal text-sm text-center' style={styles.textColor}>{`${user.email}`}</Text>
                        </View>
                        <View style={styles.line} className="my-4"/>

                        <View>
                            <View className='flex flex-row justify-between items-center my-4'>
                                <View className='flex flex-row gap-3 items-center'>
                                    <Image 
                                    source={require('../../assets/walletWhite.png')}
                                    style={{ width: 20, height: 20 }}
                                    />
                                    <Text className='font-medium text-base text-white'>Payment Methods</Text>
                                </View>
                                <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                            </View>
                            <Pressable
                                onPress={() => navigation.navigate("AccountInfo")}
                            >
                            <View className='flex flex-row justify-between items-center my-4'>
                                <View className='flex flex-row gap-3 items-center'>
                                    <Image 
                                    source={require('../../assets/AccountIcon.png')}
                                    style={{ width: 20, height: 20 }}
                                    />
                                    <Text className='font-medium text-base text-white'>Account Information</Text>
                                </View>
                                <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                            </View>
                            </Pressable>
                            <Pressable
                                onPress={() => navigation.navigate("TransactionHistory")}
                            >
                            <View className='flex flex-row justify-between items-center my-4'>
                                <View className='flex flex-row gap-3 items-center'>
                                    <Image 
                                    source={require('../../assets/transactionIcon.png')}
                                    style={{ width: 20, height: 20 }}
                                    />
                                    <Text className='font-medium text-base text-white'>Transaction History</Text>
                                </View>
                                <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                            </View>
                            </Pressable>
                            <Pressable onPress={()=> navigation.navigate('AccountInfo',{
                                changePassword: true
                            })}>
                                <View className='flex flex-row justify-between items-center my-4'>
                                    <View className='flex flex-row gap-3 items-center'>
                                        <Image 
                                        source={require('../../assets/LockIcon.png')}
                                        style={{ width: 17, height: 20 }}
                                        />
                                        <Text className='font-medium text-base text-white'>Change Password</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                            </Pressable>
                            <View className='flex flex-row justify-between items-center my-4'>
                                <View className='flex flex-row gap-3 items-center'>
                                    <Image 
                                    source={require('../../assets/infoIcon.png')}
                                    style={{ width: 20, height: 20 }}
                                    />
                                    <Text className='font-medium text-base text-white'>About</Text>
                                </View>
                                <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                            </View>
                            <Pressable onPress={OpenBottomSheet}>
                                <View className='flex flex-row justify-between items-center my-4'>
                                    <View className='flex flex-row gap-3 items-center'>
                                        <Image 
                                        source={require('../../assets/Logout.png')}
                                        style={{ width: 20, height: 20 }}
                                        />
                                        <Text className='font-medium text-base text-white'>Logout</Text>
                                    </View>
                                    <MaterialIcons name="keyboard-arrow-right" size={20} color="white" />
                                </View>
                            </Pressable>
                        </View>
                    </View>
                </ScrollView>
                <RBSheet
                    ref={refRBSheet}
                    height={210}
                    duration={250}
                    closeOnDragDown={true}
                    closeOnPressMask={true}
                    customStyles={{
                        container: {
                        borderRadius: 30,
                        backgroundColor: "#1A1D21",
                        width: width <= 430 ? width*0.95 : 400,
                        position: "relative",
                        display: "flex",
                        alignSelf: "center",
                        paddingBottom: 70,
                        marginBottom: 80
                        },
                    }}
                >   
                    <View>
                        <View style={{ flex:1, alignItems: 'center' }}>
                            <Image 
                                source={require('../../assets/headerBarIcon.png')} 
                                style={{ width: 55, height: 5, marginTop: 12 }}
                            />
                        </View>
                        <View className='p-4'>
                            <Text className='font-bold text-white text-2xl text-center'>Logout</Text>
                        
                            <View style={styles.line} className="my-4"/>
                            <Text className='font-normal text-base text-center' style={styles.textColor}>Are you sure you want to logout?</Text>
                                <View className="flex flex-row justify-between items-center px-6 py-3">
                                    <Pressable className="border border-white rounded-full" onPress={CloseBottomSheet}>
                                        <Text className="text-white rounded-full px-8 py-3 font-semibold text-xs">Cancel</Text>
                                    </Pressable>
                                    <Pressable onPress={handleLogout}>
                                        <Text 
                                            className="text-white rounded-full px-8 py-3 font-semibold text-xs"
                                            style={styles.bgColor}>Yes, Logout</Text>
                                    </Pressable>
                                </View>
                        </View>
                    </View>
                </RBSheet>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    bgColor: {
        backgroundColor: '#F75555'
    },
    textColor: {
        color: '#8D8E90'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
    },
})

export default MyAccount;