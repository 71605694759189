import { chargerActions } from "../slices/charger-slice";
import { PrepareApiProcedure } from "../../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../../services/api-requests";
import { ChargerAttributes } from "../../models/charger-model";
import { notificationService } from "../../services/notification-service";
import { getCurrentUtcSeconds, getPastUtcSeconds } from "../../components/common/utils/date-time-conversions";
import * as Location from 'expo-location';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const registerNewCharger = async (charger: ChargerAttributes) => {
    try {

    } catch (e) {

    }
}

export const getAllChargers = () => {
    return async (dispatch: (arg0: {payload: any; type: string;}) => void) => {
       console.log("getAllChargers");
        try {
            let apiQuery = PrepareApiProcedure('charger/getAll', 'GET', 'charger', '');
            /* Now the apiQuery is an object */
            //console.log("querying", apiQuery);
            const chargerList = await fetchDataFromAPI(apiQuery);
            if(chargerList.fatal === true){
                notificationService.sendNotification('error', `Unable to fetch existing systems\n ${JSON.stringify(chargerList)}`);
            }

            console.log('chargerList:', chargerList)
            dispatch(chargerActions.getAllChargers(
                {
                    chargers: chargerList
                }
            ));

        } catch (e) {
            console.error(e);
        }
    }
}

// export const getAllChargers = async (): Promise<ChargerAttributes[]> => {
//     console.log("getAllChargers");
//     let apiQuery = PrepareApiProcedure('charger/getAll', 'GET', 'charger', '');
//     let chargerList: ChargerAttributes[] = await fetchDataFromAPI(apiQuery);
//     return chargerList;
// }

export const getChargerHistory = (charger:ChargerAttributes) => {
    return async (dispatch: (arg0: {payload: any; type: string; }) => void) => {
        try {
            //create the timestamp
            const apiQuery = PrepareApiProcedure(`charger/${charger.uuid}/${getPastUtcSeconds(30 * 24 * 60 * 60)}/${getCurrentUtcSeconds()}`, 'GET', 'chargerHistory', '');
            let chargerHistoryData = await fetchDataFromAPI(apiQuery);

            dispatch(chargerActions.getChargerHistory(
                {
                    chargerHistory: chargerHistoryData
                }
            ))
        } catch (e) {
            console.error(e);
        }
    }
}

export const getRecentChargerHistory = async (userId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/recentlyUsedByUser/${userId}`, 'GET', 'chargerHistory', '');
        let recentChargerHistory = await fetchDataFromAPI(apiQuery);
        return recentChargerHistory;
    } catch (e) {
        console.error(e);
    }
}

export const updateSelectedCharger = (chargerId: string) => {
    return async (dispatch: (arg0: {payload: any; type: string;}) => void) => {
        console.log("updating selected charger", chargerId);
        try {
            const apiQuery = PrepareApiProcedure(`charger/getSingleCharger/${chargerId}`, 'GET', 'charger', '');
            let updatedCharger = await fetchDataFromAPI(apiQuery);
            if(updatedCharger.fatal === true){
                notificationService.sendNotification('error', `Unable to fetch selected charger\n ${JSON.stringify(updatedCharger)}`);
            }
            console.log("updated charger object is:", updatedCharger[0]);
            dispatch(chargerActions.updateSelectedCharger(
                {
                    selectedCharger: updatedCharger[0]
                }
            ))
        } catch (e) {
            console.error("Error updating selected charger store:", e);
        }
    }
}

export const deleteUnit = async (unitId: object) => {

}

export const getMyDistanceFromCharger = async (myLocation: Location.LocationObjectCoords, chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getMyDistanceFromCharger/${myLocation.latitude}/${myLocation.longitude}/${chargerId}`, 'GET', 'charger', '');
        let myDistance = await fetchDataFromAPI(apiQuery);
        //console.log("Querying", apiQuery);
        let meters = parseFloat(myDistance.distance);
        if (isNaN(meters)) {
            return "0";
        }
        //console.log("my distance:", myDistance);

        //Convert meters to miles
        const miles = meters * 0.000621371
        return miles.toFixed(1);
    } catch (error) {
        console.log("error getting my distance")
    }
}

export const getMyDrivingTime = async (myLocation: Location.LocationObjectCoords, chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getMyDrivingTimeFromCharger/${myLocation.latitude}/${myLocation.longitude}/${chargerId}`, 'GET', 'charger', '');
        //console.log("Querying", apiQuery);
        const myDrivingTime = await fetchDataFromAPI(apiQuery);
        //console.log("my driving time:", myDrivingTime);
        return myDrivingTime.drivingTime;
    } catch (error) {
        console.log("error getting my driving time")
    }
}


export const getResidentials = async (myLocation: Location.LocationObjectCoords) => {
       console.log("getResidentials");
        try {
            let apiQuery = PrepareApiProcedure(`https://developer.nrel.gov/api/utility_rates/v3.json?api_key=W5aYhq2cKwnz1t0KTx9c34tlCCImeUXcLzXIjLRn&lat=${myLocation.latitude}&lon=${myLocation.longitude}&detail=minimal`, 'GET', 'residence', '');
            //console.log("querying", apiQuery);
            try{
                const jwtToken = await AsyncStorage.getItem("token");
                const IpAddress = await AsyncStorage.getItem("IpAddress");
                const email = await AsyncStorage.getItem("email");
        
                const response = await fetch(apiQuery.api,
                    {
                        method: apiQuery.request.action,
                        headers:{
                            'Accept': 'application/json, text/plain',
                            'Content-Type': 'application/json',
                            'x-username': `${email}`,
                            'x-ip-address':`${IpAddress}`,
                            'authorization': 'Bearer ' + jwtToken
                        },
                        body: (apiQuery.request.data === '') ? null : JSON.stringify(apiQuery.request.data)
                    });
                
                if(!response.ok){
                    const errorData = await response.json();
                    if ((errorData.statusNum === '3003')){
                        return errorData.msg;
                    } else {
                        return errorData.msg;
                    }
                }
        
                const data = await response.json();
                console.log('getInfotestingData:', data)
                return data;
            } catch (err) {
                console.error('Error in fetchDataFromAPI:', err);
                throw err;
            }

        } catch (e) {
            console.error(e);
        }
}

export const updateCharger = async(form: any, uuid: any) => {
        try{
            const apiQuery = PrepareApiProcedure(`charger/update/${uuid}`, 'PUT', 'charger', form);
            const registerResp = await fetchDataFromAPI(apiQuery);
            console.log('registerResp:', registerResp)

            if (!registerResp || registerResp === 'jwt malformed') {
                notificationService.sendNotification("error", `Could not update this charger, bad token`);
            } else {
                notificationService.sendNotification("success", 'Successfully updated your charger!');
                return 0;
            }
        } catch (e) {
            console.error("Error while registering charger", e);
            notificationService.sendNotification("error", `Error while registering charger ${e.message}`);
        }
}

export const disableCharger = async(uuid: any) => {
    try{
        const apiQuery = PrepareApiProcedure(`charger/disable/${uuid}`, 'GET', 'charger', '');
        const disableCharger = await fetchDataFromAPI(apiQuery);
        console.log('disableCharger:', disableCharger)

        if (!disableCharger || disableCharger === 'jwt malformed') {
            notificationService.sendNotification("error", `Could not disable this charger`);
        } else {
            notificationService.sendNotification("success", disableCharger.message);
        }
    } catch (e) {
        console.error("Error while registering charger", e)
    }
}


export const enableCharger = async(uuid: any) => {
    try{
        const apiQuery = PrepareApiProcedure(`charger/enable/${uuid}`, 'GET', 'charger', '');
        const enableCharger = await fetchDataFromAPI(apiQuery);
        console.log('enableCharger:', enableCharger)

        if (!enableCharger || enableCharger === 'jwt malformed') {
            notificationService.sendNotification("error", `Could not enable this charger`);
        } else {
            notificationService.sendNotification("success", enableCharger.message);
        }
    } catch (e) {
        console.error("Error while registering charger", e)
    }
}

//FUNCTION: getMyChargers
// DESCRIPTION: Get my registered chargers for the charging history screen
export const getMyChargers = () => {
    return async (dispatch: (arg0: {payload: any; type: string;}) => void) => {
        try {
            const apiQuery = PrepareApiProcedure(
                'charger/getMyChargers',
                'GET',
                'charger',
                ''
            );
            const myChargerData = await fetchDataFromAPI(apiQuery);
            console.log(myChargerData);
            if (Array.isArray(myChargerData)){
                dispatch(chargerActions.getMyChargers(
                    {
                        myChargers: myChargerData
                    }
                ));
            } 
            else if (myChargerData === 'jwt malformed') {
                notificationService.sendNotification('error', `You are not logged in! Please log in and try again`)
            } else {
                notificationService.sendNotification('error', `Error retrieving chargers ${myChargerData}`)
            }
        } catch (error) {
            console.log("Error getting my chargers:", error);
        }
    }
}

export const chargerQrScan = (chargerSerial: string) => {
    return async (dispatch: (arg0: {payload: any; type: string;}) => void) => {
        try {
            const apiQuery = PrepareApiProcedure(
                `charger/chargerscan/${chargerSerial}`,
                'GET',
                'charger',
                ''
            );
            const chargerData = await fetchDataFromAPI(apiQuery);
            if (chargerData && chargerData.errStatus === undefined) {
                const uuid = chargerData;
                dispatch(updateSelectedCharger(uuid)); // Update Redux
            } else {
                console.error('Failed to fetch charger');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
}