import React from 'react';
import { View, Image, StyleSheet, Dimensions} from 'react-native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ChargerStation from "../pages/ChargingStation";
import ChargerSelection from "../pages/ChargerSelection";
import ChargerValidation from '../pages/ChargerValidation';
import AddModifyCharger from '../pages/AddModifyCharger';
import MyChargers from '../pages/MyChargers';
import DatePicker from './DatePicker';
import ChargingScreen from '../pages/ChargingScreen';
import ChargeSummary from '../pages/ChargeSummary';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import MyAccount from '../pages/MyAccount';
import AccountInfo from '../pages/AccountInfo';
import TransactionHistory from '../pages/TransactionHistory';
import CreateAccount from '../pages/CreateAccount';
import LoginWithAccount from '../pages/LoginWithAccount';
import WelcomeScreen from '../pages/WelcomeScreen';
// import SplashScreenNew from '../pages/SplashScreenNew';
import Footer from './Footer';
import { ChargerAttributes } from '../models/charger-model';
import LoginScreen from '../pages/login';
import ManageCharger from '../pages/ManageCharger';
import { ChargerHistoryAttributes } from '../models/charger-history-model';
import ChargerScanHandler from './common/ChargerScanHandler';

const Tab = createBottomTabNavigator();


export type RootStackParamList = {
  Login: undefined;
  Profile: { userId: string };
  ChargerScan: {
    serialNum: string,
  }
  ChargerStation: {
    myDistanceFromCharger?: string, 
    myTimeFromCharger?: string
  };
  ChargeSummary: {
    charger: ChargerAttributes
    startTime: Date,
    endTime: Date
  };
  AddModifyCharger:{
    unit_serial?: string,
    editCharger?: boolean,
  };
  ChargerSelection: {
    tabSelect: string
  };
  ManageChargers: {
    myChargers: ChargerAttributes[],
    myChargerHistories: ChargerHistoryAttributes[]
  },
  ChargingScreen: undefined
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const MeanderStack = () => {
  return (

  <Stack.Navigator
    initialRouteName="WelcomeScreen"
    screenOptions={{ headerShown: true }}
  >
    <Stack.Screen name="LoginScreen" component={LoginScreen} />
    <Stack.Screen name="ChargerScan" component={ChargerScanHandler} />
    <Stack.Screen name="ChargerStation" component={ChargerStation} />
    <Stack.Screen name="ChargerSelection" component={ChargerSelection} />
    <Stack.Screen name="ChargerValidation" component={ChargerValidation} />
    <Stack.Screen name="AddModifyCharger" component={AddModifyCharger} />
    <Stack.Screen name="MyChargers" component={MyChargers} />
    <Stack.Screen name="DatePicker" component={DatePicker} />
    <Stack.Screen name="ChargingScreen" component={ChargingScreen} />
    <Stack.Screen name="ChargeSummary" component={ChargeSummary} />
    <Stack.Screen name="MyAccount" component={MyAccount} />
    <Stack.Screen name="AccountInfo" component={AccountInfo} />
    <Stack.Screen name="TransactionHistory" component={TransactionHistory} />
    <Stack.Screen name="CreateAccount" component={CreateAccount} /> 
    <Stack.Screen name="LoginWithAccount" component={LoginWithAccount} />
    <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} />
    <Stack.Screen name="ManageChargers" component={ManageCharger} />
    {/* <Stack.Screen name="SplashScreenNew" component={SplashScreenNew} /> */}
    <Stack.Screen name="Footer" component={Footer} />
  </Stack.Navigator>

  );
};

export default MeanderStack;