import React, {useEffect, useRef, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    Modal,
    ScrollView,
    Pressable,
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { getMyChargingHistory } from "../services/charger-history-service";
import { ChargerHistoryAttributes } from "../models/charger-history-model";

const { width, height } = Dimensions.get("window");

export default function MyChargerHistoryAtAGlance(props:
  {
    handleFilter: () => void, 
    customDates: { start: string | null, end: string | null },
    filteredChargers: ChargerHistoryAttributes[],
    totalKwH: number,
    totalRevenue: number,
  }) {

  return(
      <React.Fragment>
        <View className="flex flex-row items-center justify-center gap-4">
            <Pressable onPress={props.handleFilter}>
                <Image
                    source={require('../../assets/searchFilteringWhite.png')}
                    style={styles.filterIcon}
                    className="p-5 rounded-full"
                />
            </Pressable>
            <View>
                <Text className="text-lg font-semibold text-white">My Chargers</Text>
                <Text className="text-xs font-normal" style={styles.TextColor1}>{`${props.customDates.start} - ${props.customDates.end}`}</Text>
            </View>
        </View>

        <View className="relative mt-4">
            <Image
                source={require('../../assets/chargingHistoryBgImage.png')}
                style={{
                    height: width <= 430 ? width * 0.64 : 270,
                    resizeMode: "cover",
                }}
            />
            <View
                className="absolute flex flex-row items-center w-full"
                style={{ height: width <= 430 ? width * 0.35 : 150 }}
            >
                <View className="items-center flex-1 w-50">
                    <View className="flex flex-row items-center gap-3">
                        <Image
                        source={require('../../assets/stationPlugInWhite.png')}
                        style={{
                            height: 39,
                            width: 30,
                        }}
                        />
                        <View>
                            <Text className="text-4xl font-medium text-white">{props.filteredChargers?.length}</Text>
                            <Text className="text-xs font-medium text-white">Total Charges</Text>
                        </View>
                    </View>
                </View>
                <View className="items-center flex-1 w-50">
                    <View className="flex flex-row items-center gap-4">
                        <Image
                        source={require('../../assets/lightning.png')}
                        style={{
                            height: 40,
                            width: 23,
                        }}
                        />
                        <View>
                            <Text className="text-4xl font-medium text-white">{props.totalKwH}</Text>
                            <Text className="text-xs font-medium text-white">kWh charged</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View
                className="absolute flex flex-row items-center w-full"
                style={{ 
                    height: width <= 430 ? width * 0.33 : 140,
                    marginTop: width <= 430 ? width * 0.33 : 140,
                  }}
            >
                <View className="items-center flex-1 w-full">
                    <View className="flex flex-row items-center gap-3">
                        <Image
                        source={require('../../assets/money.png')}
                        style={{
                            height: 34,
                            width: 60,
                        }}
                        />
                        <View>
                            
                            <View className="flex flex-row items-center gap-1">
                                <FontAwesome name="dollar" size={28} color="white" />
                                <Text className="text-4xl font-medium text-white">{props.totalRevenue}</Text>
                            </View>
                            <Text className="text-xs font-medium text-white">Total Revenue</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
      </React.Fragment>

  )
    
}

const styles = StyleSheet.create({
  safeArea: {
      flex: 1,
      backgroundColor: '#0B1E1D',
    },
  containerBgColor: {
      backgroundColor: '#0B1E1D',
  },
  BgColor: {
      backgroundColor: '#51B868'
  },
  BgColor1: {
      backgroundColor: '#39BEB780'
  },
  BgColor2: {
      backgroundColor: '#F97777'
  },
  BgColor3: {
      backgroundColor: '#39BEB7'
  },
  BgColor4:{
      backgroundColor: '#636363'
  },
  TextColor1: {
      color: '#E8E8E9'
  },
  textColor3: {
      color: "#8D8E90"
  },
  line: {
      borderBottomColor: "#636363",
      borderBottomWidth: 1,
      borderStyle: 'dashed',
      marginVertical: 20,
  },
  filterIcon: {
      height: 17,
      width: 17,
      backgroundColor: '#39BEB7',
  },
  centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
  modalView: {
      margin: 20,
      backgroundColor: "#292D33",
      borderRadius: 30,
      padding: 10,
      shadowColor: "#000",
      shadowOffset: {
          width: 0,
          height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      width: 290,
      height: 250,
  },
  dropdownTrigger: {
      width: width <= 430 ? width*0.4 : 150,
  },
  dropdownContainer: {
      position: 'absolute',
      top: 30,
      left: 0,
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 2,
      width: width <= 430 ? width * 0.4 : 150,
      zIndex: 1000,
  },
  alignEllipses: {
      fontWeight: '500',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 150,
  },
  tooltipContainer: {
      borderRadius: 10,
      padding: 10,
      minWidth: 50,
      maxWidth: 500,
      width: 'auto',
      height: 'auto'
    }
})